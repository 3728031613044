const attributes = {
  base: {
    preserveAspectRatio: "xMidYMid meet",
    vectorEffect: "non-scaling-stroke",
    version: "1.1",
    xmlns: "http://www.w3.org/2000/svg",
  },
  /** https://github.com/facebook/Rapid/issues/1085 */
  noAutofill: {
    autocorrect: "off",
    autocapitalize: "off",
    "data-1p-ignore": "",
    "data-lpignore": "true",
    spellcheck: "false",
  },
};

export default attributes;
