import * as Sentry from "@sentry/nextjs";

if (process.env.NEXT_PUBLIC_SABLIER_ENV === "production") {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    tracesSampleRate: 0.05,
    environment: "production",
    ignoreErrors: [
      "Talisman extension has not been configured yet.",
      "Cannot redefine property: ethereum",
      "User rejected the request",
      "Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing.",
      "Socket stalled when trying to connect to wss://relay.walletconnect.org",
      "Backpack couldn't override `window.ethereum`",
      "window.ethereum._handleChainChanged is not a function",
      "No matching key. session topic doesn't exist:",
      "Failed to publish payload, please try again.",
    ],
  });
}
