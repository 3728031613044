import { css } from "styled-components";

const styles = {
  capital: css`
    &,
    & > * {
      &::first-letter {
        text-transform: capitalize;
      }
    }
  `,
  column: css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  `,
  columnCentered: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,
  container: css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: ${(props) => props.theme.sizes.container};
    margin: 0 auto;
    padding: 0 ${(props) => props.theme.sizes.edge};
    box-sizing: border-box;

    ${(props) => props.theme.medias.maxMD} {
      padding: 0 calc(${(props) => props.theme.sizes.edge} * 1);
    }
  `,
  get contained() {
    return css`
      ${this.container}

      ${(props) => props.theme.medias.maxMD} {
        padding: 0 calc(${(props) => props.theme.sizes.edge} * 1 / 2);
      }
    `;
  },

  dividerHorizontal: css`
    width: 100%;
    height: 2px;
    background-color: ${(props) => props.theme.colors.border};
  `,
  dividerVertical: css`
    width: 2px;
    height: 100%;
    background-color: ${(props) => props.theme.colors.border};
  `,

  ellipsis: css`
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  `,

  ellipsisAdaptive: css`
    display: table;
    width: 100%;
    table-layout: fixed;

    & > p,
    & > label {
      display: table-cell;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  `,

  hiddenScrollbar: css`
    -ms-overflow-style: none;
    scrollbar-color: transparent transparent;
    overflow-x: auto;

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb,
    &::-webkit-slider-thumb,
    &::-moz-scrollbar {
      display: none !important;
      width: 0 !important;
      height: 0 !important;
      background: transparent !important;
    }
  `,
  row: css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  `,
  rowCentered: css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  `,

  shinny: css`
    position: absolute;
    top: var(--top);
    bottom: var(--bottom);
    content: "";
    z-index: ${(props) => props.theme.sizes.zIndexOver};
    width: 70%;
    height: 2px;
    background: linear-gradient(
      90deg,
      var(--end),
      var(--highlight),
      var(--end)
    );
  `,

  textBreadcrumb: css`
    font-weight: 700;
    font-size: 10.5pt;
    line-height: 17pt;
  `,
  textButton: css`
    font-weight: 700;
    font-size: 12pt;
  `,
  textButtonMini: css`
    font-weight: 700;
    font-size: 11pt;
  `,
  textBase: css`
    font-weight: 600;
  `,
  textElement: css`
    font-weight: 600;
    font-size: 12pt;
    line-height: 16pt;
  `,
  textGradientPrimary: css`
    background-image: ${(props) => props.theme.gradients.primary};
    background-position-x: 0;
    background-size: 100%;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
  `,
  textInput: css`
    font-weight: 600;
    font-size: 12pt;
  `,
  textMenu: css`
    font-weight: 600;
    font-size: 14pt;
    font-family: ${(props) => props.theme.fonts.secondary};
  `,
  textParagraph: css`
    font-weight: 500;
    font-size: 12pt;
    line-height: 150%;
  `,
  textParagraphMaxi: css`
    font-weight: 500;
    font-size: 14pt;
    line-height: 150%;
  `,
  textParagraphMini: css`
    font-weight: 500;
    font-size: 10.5pt;
    line-height: 140%;
  `,
  textPlaceholder: css`
    color: ${(props) => props.theme.colors.dark900};
    font-weight: 500;
    font-size: 12pt;
  `,
  textSection: css`
    font-weight: 600;
    font-size: 24pt;
    font-family: ${(props) => props.theme.fonts.secondary};

    ${(props) => props.theme.medias.maxMD} {
      font-size: 18pt;
    }
  `,
  textTitle: css`
    font-weight: 600;
    font-size: 12pt;
    line-height: 140%;
  `,
};

export default styles;
