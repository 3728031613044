/*eslint no-undef: "error"*/
/*eslint-env node*/
const i18nConfig = {
  locales: ["en"],
  defaultLocale: "en",
  defaultNS: "common",
  pages: {
    "*": ["common"],
  },
  loadLocaleFrom: (locale, namespace) => {
    return import(`@sablier/v2-locales/src/${locale}/${namespace}.json`).then(
      (m) => m.default,
    );
  },
};

module.exports = i18nConfig;
