import { general } from "./colors";

export const palette = {
  dark: `linear-gradient(225deg, ${general.dark150} 0%, ${general.dark300} 100%)`,
  darkVertical: `linear-gradient(0deg, ${general.dark150} 0%, ${general.dark300} 100%)`,
  primary: `linear-gradient(225deg, ${general.primaryEnd} 0%, ${general.primaryStart} 100%)`,
  purple: `linear-gradient(225deg, ${general.purpleEnd} 0%, ${general.purpleStart} 100%)`,
  red: `linear-gradient(225deg, ${general.redEnd} 0%, ${general.redStart} 100%)`,
  thumbnail: `linear-gradient(225deg, ${general.thumbnailStart} 0%, ${general.thumbnailEnd})`,
  thumbnailGray: `linear-gradient(225deg, ${general.gray500Transparent} 0%, ${general.gray500})`,
  thumbnailPurple: `linear-gradient(225deg, ${general.purpleTransparent} 0%, ${general.purple})`,
  thumbnailWhite: `linear-gradient(225deg, ${general.whiteTransparent} 0%, ${general.white60})`,
  secondary: `linear-gradient(225deg, ${general.secondaryEnd} 0%, ${general.secondaryStart} 99%)`,

  fadePrimary: `linear-gradient(205deg, ${general.primaryStart} 0%, ${general.primaryEnd} 20%, ${general.primaryTransparent} 40%, ${general.primaryTransparent} 70%, ${general.primaryMiddle} 100%)`,
  fadePurple: `linear-gradient(205deg, ${general.purpleEnd} 0%, ${general.purpleStart} 15%, ${general.transparent} 40%, ${general.transparent} 80%, ${general.purpleStart} 140%)`,
  fadeGray: `linear-gradient(205deg, ${general.dark200} 25%, ${general.transparent} 60%, ${general.transparent} 70%, ${general.dark1000} 140%)`,
};

const gradients = {
  ...palette,

  svgId: {
    dark: "gradient-dark",
    darkVertical: "gradient-dark-vertical",
    primary: "gradient-primary",
    purple: "gradient-purple",
    red: "gradient-red",
    thumbnail: "gradient-thumbnail",
    thumbnailGray: "gradient-thumbnail-gray",
    thumbnailPurple: "gradient-thumbnail-purple",
    thumbnailWhite: "gradient-thumbnail-white",
    secondary: "gradient-secondary",
  },

  svgUrl: {
    dark: "url(#gradient-dark)",
    darkVertical: "url(#gradient-dark-vertical)",
    primary: "url(#gradient-primary)",
    purple: "url(#gradient-purple)",
    red: "url(#gradient-red)",
    thumbnail: "url(#gradient-thumbnail)",
    secondary: "url(#gradient-secondary)",
    thumbnailGray: "url(#gradient-thumbnail-gray)",
    thumbnailPurple: "url(#gradient-thumbnail-purple)",
    thumbnailWhite: "url(#gradient-thumbnail-white)",
  },
};

function Generate({
  id,
  from,
  to,
  transform = "",
}: {
  id: string;
  from: string;
  to: string;
  transform?: string;
}) {
  return (
    <linearGradient gradientTransform={transform} id={id}>
      <stop offset={"0%"} stopColor={from} />
      <stop offset={"100%"} stopColor={to} />
    </linearGradient>
  );
}

export function Definitions() {
  return (
    <svg
      style={{
        height: 0,
        width: 0,
        position: "absolute",
        pointerEvents: "none",
      }}
    >
      <defs>
        <Generate
          id={gradients.svgId.dark}
          from={general.dark300}
          to={general.dark150}
        />
        <Generate
          id={gradients.svgId.darkVertical}
          from={general.dark300}
          to={general.dark150}
          transform={"rotate(90)"}
        />
        <Generate
          id={gradients.svgId.primary}
          from={general.primaryStart}
          to={general.primaryEnd}
        />
        <Generate
          id={gradients.svgId.purple}
          from={general.purpleStart}
          to={general.purpleEnd}
        />
        <Generate
          id={gradients.svgId.red}
          from={general.redStart}
          to={general.redEnd}
        />
        <Generate
          id={gradients.svgId.thumbnail}
          from={general.thumbnailStart}
          to={general.thumbnailEnd}
          transform={"rotate(90)"}
        />
        <Generate
          id={gradients.svgId.thumbnailGray}
          from={general.gray500}
          to={general.gray500Transparent}
          transform={"rotate(85)"}
        />
        <Generate
          id={gradients.svgId.thumbnailWhite}
          from={general.white60}
          to={general.whiteTransparent}
          transform={"rotate(85)"}
        />
        <Generate
          id={gradients.svgId.thumbnailPurple}
          from={general.purple50}
          to={general.purpleTransparent}
          transform={"rotate(85)"}
        />
        <Generate
          id={gradients.svgId.secondary}
          from={general.secondaryStart}
          to={general.secondaryEnd}
        />
      </defs>
    </svg>
  );
}

export default gradients;
