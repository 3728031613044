import Papa from "papaparse";

async function parseAsync<T, TFile extends Papa.LocalFile = Papa.LocalFile>(
  file: TFile,
  config: Omit<Papa.ParseLocalConfig<T, TFile>, "complete" | "error">,
): Promise<{ results: Papa.ParseResult<T>; file: TFile }> {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      ...config,
      complete: (results, file) => {
        resolve({ results, file });
      },
      error: (error) => {
        reject(error);
      },
    });
  });
}

declare module "papaparse" {
  export function parseAsync<T, TFile extends LocalFile = LocalFile>(
    file: TFile,
    config: Omit<Papa.ParseLocalConfig<T, TFile>, "complete" | "error">,
  ): Promise<{ results: Papa.ParseResult<T>; file: TFile }>;
}

Papa.parseAsync = parseAsync;

export default Papa;
