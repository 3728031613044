import { Catamaran, Roboto_Mono, Urbanist } from "next/font/google";

export const fallback =
  '-apple-system, BlinkMacSystemFont, system-ui, "Segoe UI", "Helvetica Neue", Arial, sans-serif';

const fonts = {
  fallback,
  primary: `var(--font-urbanist), ${fallback}`,
  secondary: `var(--font-catamaran), ${fallback}`,
  monospace: `var(--font-roboto-mono), monospace, ${fallback}`,
};

const roboto = Roboto_Mono({
  subsets: ["latin"],
  variable: "--font-roboto-mono",
});

const urbanist = Urbanist({
  subsets: ["latin"],
  variable: "--font-urbanist",
});

const catamaran = Catamaran({
  subsets: ["latin"],
  variable: "--font-catamaran",
});

const nextFonts = {
  catamaran,
  roboto,
  urbanist,
};

export { nextFonts };
export default fonts;
